<template>
  <div>
    <!-- Carousel -->
    <Carousel :banners="banners" />
    <!-- Menu -->
    <Menu :wallet="wallet" class="mb-4 d-lg-none" />
    <!-- All games -->
    <div class="" style="overflow-x: auto; position: relative">
      <div class="d-flex" ref="games">
        <div class="symbol symbol-md-75 symbol-50 symbol-dark mr-3 flex-shrink-0 text-center game">
          <div class="symbol-label p-1" @click="selectGame('')" v-bind:class="{'bg-white': selectedGame === '' }">
            <img src="/images/allgame2.png" alt="" class="h-100 w-100" />
          </div>
          <div class="font-size-sm gamename">All Games</div>
        </div>
        <div v-for="game in games" v-bind:key="game.alias" class="symbol symbol-md-75 symbol-50 symbol-dark mr-3 flex-shrink-0 text-center game">
          <div class="symbol-label" @click="selectGame(game.alias)" v-bind:class="{'bg-white': selectedGame === game.alias }">
            <img :src="game.img" alt="" class="h-75" />
          </div>
          <div class="font-size-sm gamename">{{ game.alias }}</div>
        </div>
      </div>
    </div>
    <!-- Banner 300px -->
    <div v-if="headads" class="banner rounded">
      <div class="image300" :style="headadsstyle">
        <a v-if="headads.link" :href="headads.link" target="_blank"></a>
      </div>
    </div>
    <!-- All matches -->
    <div class="card card-custom gutter-b card-stretch mt-4">
      <div class="pt-0">
        <div class="d-flex">
          <div class="d-none d-sm-block col-12 col-sm-4 p-2">ทัวร์นาเม้นต์</div>
          <div class="col-12 col-sm-4 p-2">คู่การแข่งขัน</div>
          <div class="d-none d-sm-block col-12 col-sm-4 p-2 text-info">เวลาปิดทายผล (Timezone: {{ timezone }})</div>
        </div>
        <template v-for="(m, idx) in matches">
          <div v-bind:key="`c${idx}`">
            <div class="row pt-1 pb-1 matchhead font-size-sm">
              <div class="col-12 col-sm-8 d-inline-block d-sm-flex px-0">
                <div class="col-12 col-sm-6 px-2 d-flex">
                  <div class="d-flex">
                      <div class="symbol symbol-20 symbol-dark mr-2 flex-shrink-0 text-center">
                        <div class="symbol-label">
                          <img v-if="m.game.img" :src="m.game.img" alt="" class="h-100 w-100" />
                        </div>
                      </div>
                      <div class="symbol symbol-20 symbol-dark mr-2 flex-shrink-0 text-center">
                        <div class="symbol-label">
                          <img v-if="m.tour.img" :src="m.tour.img" alt="" class="h-100 w-100" />
                        </div>
                      </div>
                    </div>
                  <div class="">{{ m.tour.name }}</div>
                </div>
                <div class="col-12 col-sm-6 px-2">
                  <router-link :to="`/matches/${m.no}`" class="text-white d-flex">
                    <span>{{ m.name }}</span>
                    <i v-if="m.embed===1" class="fab fa-youtube pl-1" />
                    <i v-if="m.embed===2" class="fab fa-twitch pl-1" />
                  </router-link>
                </div>
              </div>
              <div class="col-12 col-sm-4 px-2 text-info">
                <span class="font-weight-bolder mr-2">{{ date(m) }}</span>
                <span>{{ time(m) }}</span>
              </div>
            </div>
            <div class="px-4 pb-1 matchdata font-size-xs">
              <!-- No teams -->
              <div v-if="m.noteams.length > 0" class="row pt-2">
                <div class="col-12 px-4">
                  <b-row no-gutters>
                    <b-col v-for="(c, k) in m.noteams" v-bind:key="`${idx}-${k}`" class="pr-1 mb-1" :cols="4" :sm="3" :md="2">
                      <b-card no-body class="p-1 pool" v-on:click="openrate(m, c)" :class="pool(m, c)">
                        <b-row align-v="center" no-gutters>
                          <div class="flex-shrink-0 px-2" v-if="c.comment">
                            <b-badge variant="dark" class="extra">{{ c.comment }}</b-badge>
                          </div>
                          <b-col class="text-center">
                            <div>{{ c.name }}</div>
                            <div>{{ c.rate }}</div>
                          </b-col>
                        </b-row>
                      </b-card>
                    </b-col>
                  </b-row>
                </div>
              </div>
              <div v-if="teams(m)">
                <!-- Headers -->
                <div class="row align-items-center">
                  <div class="col-4 px-4 py-1">
                    <span>Teams</span>
                  </div>
                  <div class="col-8 pr-5 pl-2 d-none d-sm-block">
                    <b-row no-gutters align-v="center">
                      <b-col v-for="(c, j) in m.choices" v-bind:key="`${idx}.${j}`" class="pr-2 text-center" cols="*" :sm="3">
                        <span>{{ c.name }}</span>
                      </b-col>
                    </b-row>
                  </div>
                </div>
                <!-- Teams -->
                <div v-for="(t, k) in showteams(m)" v-bind:key="`${idx}-${k}`" class="row align-items-center">
                  <div class="col-6 col-sm-4 px-4">
                    <b-row align-v="center" no-gutters>
                      <b-col cols="auto">
                        <div class="symbol symbol-20 symbol-dark mr-3 flex-shrink-0">
                          <div class="symbol-label">
                            <img v-if="t.img" :src="t.img" alt="" class="h-100 w-100" />
                          </div>
                        </div>
                      </b-col>
                      <b-col cols="auto" v-if="m.teams.length === 2">
                        <i class="fas fa-square mr-2 mb-2 icon-xs" v-bind:class="teamcolor(k)"></i>
                      </b-col>
                      <b-col>{{ t.name }}</b-col>
                    </b-row>
                  </div>
                  <div class="col-6 col-sm-8 pr-5 pl-1 mb-1">
                    <b-row no-gutters align-v="center">
                      <b-col v-for="(c, j) in t.choices" v-bind:key="`${idx}-${k}-${j}`" class="pr-1" cols="*" :sm="3">
                        <b-card no-body class="p-1 pool" v-on:click="openrate(m, c, t)" :class="pool(m, c, t)">
                          <b-row align-v="center" no-gutters>
                            <div class="flex-shrink-0" v-if="c.comment">
                              <b-badge variant="dark" class="extra">{{ c.comment }}</b-badge>
                            </div>
                            <b-col class="text-center">
                              <div class="d-block d-sm-none"><strong>{{ c.name }}</strong></div>
                              <div>{{ c.rate }}</div>
                            </b-col>
                          </b-row>
                        </b-card>
                      </b-col>
                    </b-row>
                  </div>
                  <!-- Versus bar -->
                  <div v-if="versus(m, k)" class="col-6 col-sm-8 offset-6 offset-sm-4 mb-1 pl-1">
                    <b-col class="pl-0 pr-2" cols="*" :sm="3">
                      <b-progress class="bg-dark" height="3px">
                        <b-progress-bar class="team1" :value="percteam1(m)" />
                        <b-progress-bar class="team2" :value="percteam2(m)" />
                      </b-progress>
                    </b-col>
                  </div>
                </div>
                <!-- More -->
                <div v-if="m.teams.length > 3 && m.moreteam === false" class="row align-items-center">
                  <b-btn variant="primary" @click="toggleMore(m)" size="sm">+{{ m.teams.length - 2 }} teams</b-btn>
                </div>
                <div v-if="m.moreteam" class="row align-items-center">
                  <b-btn variant="primary" @click="toggleMore(m)" size="sm">Hide</b-btn>
                </div>
              </div>
            </div>
          </div>
        </template>
        <div v-if="loading" class="row mt-4">
          <div class="col-12 text-center">กำลังโหลด</div>
        </div>
        <div v-if="nomatch" class="row mt-4">
          <div class="col-12 text-center">ยังไม่มีให้ทายผลการแข่งขัน</div>
        </div>
      </div>
    </div>

    <b-modal v-model="dialogLogin" content-class="shadow" hide-header hide-footer centered>
      <div class="h4 text-center">เข้าสู่ระบบหรือลงทะเบียนเพื่อร่วมทายผล</div>
      <b-row align-h="center" class="mt-8">
        <b-col cols="auto">
          <b-btn variant="primary" to="/login">Login</b-btn>
          <b-btn variant="primary" class="ml-4" to="/register">Register</b-btn>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal v-model="dialogPoint" content-class="shadow" hide-header hide-footer centered>
      <div class="h4 text-center">รับแต้มครั้งแรกสำหรับการทายผล</div>
      <div class="h6 text-center">และรับแต้มทายผลเพิ่มที่แถบผู้ใช้ (คลิกที่ชื่อด้านบน)</div>
      <b-row align-h="center" class="mt-8">
        <b-col cols="auto">
          <b-btn variant="primary" @click="getpoint">Get Point</b-btn>
          <b-btn variant="primary" class="ml-4" @click="dialogPoint=false">Cancel</b-btn>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal v-model="setrate" content-class="shadow" hide-header hide-footer centered>
      <div>ทายผล</div>
      <div class="h4 mt-5">คะแนนที่สามารถใช้ได้ <strong>{{ walletpoint }}</strong> คะแนน</div>
      <b-row class="my-8" align-v="center">
        <b-col :cols="10">
          <div class="d-inline-flex align-items-center">
            <div class="symbol symbol-20 symbol-light mr-3 flex-shrink-0 text-center">
              <div class="symbol-label">
                <img :src="selected.tourimg" alt="" class="h-100 w-100" />
              </div>
            </div>
            <div class="">{{ selected.tourname }}</div>
          </div>
          <div class="h5">{{ selected.matchname }}</div>
          <div class="h6">{{ selected.team }}&nbsp;<strong>{{ selected.title }}</strong></div>
        </b-col>
        <b-col :cols="2">
          <div v-if="selected.teamlogo" class="symbol symbol-50 symbol-light mr-3 flex-shrink-0">
            <div class="symbol-label">
              <img :src="selected.teamlogo" alt="" class="h-100 w-100" />
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row no-gutters align-v="center">
        <b-col :cols="4" class="mr-4">จำนวนคะแนน</b-col>
        <b-col cols="auto" class="mr-2"><b-form-input v-model="setpoint" size="sm" style="width:90px" /></b-col>
        <b-col cols="auto">x {{ selected.rate }}</b-col>
      </b-row>
      <b-row no-gutters align-v="center" class="mt-4">
        <b-col :cols="4" class="mr-4">คะแนนที่ได้หากทายถูก</b-col>
        <b-col cols="auto"><strong>{{ calcpoint }} คะแนน</strong></b-col>
      </b-row>
      <b-row align-h="center" class="mt-8">
        <b-col cols="auto">
          <b-btn variant="primary" @click="submit">Submit</b-btn>
          <b-btn variant="light" class="ml-4" @click="cancel">Cancel</b-btn>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import dayjs from 'dayjs'

import Carousel from "@/view/content/Carousel.vue";
import Menu from "./Menu";
import { UPDATE_WALLET_POINT } from "@/core/services/store/profile.module";

export default {
  name: "dashboard",
  components: {
    Carousel,
    Menu
  },
  mounted() {
    this.recvData()
    this.recvMatches()
  },
  methods: {
    link(game) {
      return `/wallets/${this.wallet}/games/${game.alias}`
    },
    recvData() {
      this.axios.get(`/wallets/${this.wallet}/games`).then(
        ({ data }) => {
          this.info.no = data.no
          this.games = data.games
          this.banners = data.banners || []
          this.headads = data.headads || null
          this.pageads = data.pageads || {}
        }
      )
    },
    recvMatches() {
      this.loading = true
      var opt = {}

      if (this.selectedGame !== '') {
        opt.params = { game: this.selectedGame }
      }

      this.axios.get(`/wallets/${this.wallet}/predict`, opt).then(
        ({ data }) => {
          this.matches = data.matches
          this.matches.forEach(m => {
            this.$set(m, 'moreteam', false)
            this.$set(m, 'morenoteam', false)
          })

          this.points = data.points

          var sp = {}
          for (var k in this.points) {
            for (var kk in this.points[k]) {
              for (var kkk in this.points[k][kk]) {
                sp[`${k}${kkk}`] = this.points[k][kk][kkk]
              }
            }
          }

          this.selectPools = sp
        }
      ).finally(() => {
        this.loading = false
      })
    },
    date (m) {
      return dayjs(m.time).format('DD/MM/YYYY')
    },
    time (m) {
      return dayjs(m.time).format('HH:mm')
    },
    padding (match) {
      return this.teams(match) ? 'pb-5' : ''
    },
    teams (match) {
      return match.teams != null && match.teams.length > 0
    },
    selectGame (game) {
      this.selectedGame = game
      this.recvMatches()
    },
    openrate (m, c, t) {
      // Guest
      if (this.guest) {
        this.dialogLogin = true
        return
      }

      // Not started
      if (this.walletpoint === -1) {
        this.dialogPoint = true
        return
      }

      var p = 0

      if (t != null) {
        this.selected.choice = p = (t.no * 100) + c.no
        this.selected.team = t.name
        this.selected.teamlogo = t.img
      } else {
        this.selected.choice = p = c.no
        this.selected.team = ''
        this.selected.teamlogo = ''
      }

      this.selected.tourimg = m.tour.img
      this.selected.tourname = m.tour.name
      this.selected.matchname = m.name

      this.selected.title = c.name
      this.selected.match = m.no
      this.selected.rate = c.rate

      if (this.selected.wallet === 0) {
        this.selected.wallet = this.info.no
      }

      var mkey = `m${m.no}`
      var wkey = `w${this.selected.wallet}`
      var ckey = `c${p}`

      if (this.points[mkey] != null && this.points[mkey][wkey] != null && this.points[mkey][wkey][ckey] != null) {
        this.setpoint = this.points[mkey][wkey][ckey]
      } else {
        this.setpoint = 0
      }

      this.setrate = true
    },
    submit () {
      if (this.setpoint < 0) {
        return
      }

      var p = this.selected.choice
      var m = this.selected.match
      var w = this.selected.wallet

      if (this.points[`m${m}`] != null && this.points[`m${m}`][`w${w}`] != null && this.points[`m${m}`][`w${w}`][`c${p}`] != null) {
        if (this.points[`m${m}`][`w${w}`][`c${p}`] == this.setpoint) {
          return
        }
      }

      var params = {
        match: m,
        choice: p,
        pts: this.setpoint
      }

      this.axios.post(`/wallets/${this.wallet}/placepoint`, params).then(
        ({ data }) => {
          var c = data.choice
          var t = data.match

          if (this.points[`m${t}`] == null) {
            this.$set(this.points, `m${t}`, {})
          }

          if (this.points[`m${t}`][`w${w}`] == null) {
            this.$set(this.points[`m${t}`], `w${w}` , {})
          }

          this.$set(this.points[`m${t}`][`w${w}`], `c${c}`, data.pts)
          this.$set(this.selectPools, `m${t}c${c}`, data.pts)
          this.cancel()

          this.$store.dispatch(UPDATE_WALLET_POINT, { no: data.wallet, points: data.ptsleft })
        }
      )
    },
    cancel () {
      this.setrate = false
    },
    showteams (match) {
      if (match.teams.length > 3 && match.moreteam === false) {
        return match.teams.slice(0, 2)
      }

      return match.teams
    },
    versus (match, key) {
      return key === 0 && match.teams.length === 2
    },
    teamcolor (key) {
      if (key === 0) {
        return 'team1'
      } else {
        return 'team2'
      }
    },
    percteam1 (match) {
      return match.perc || 0
    },
    percteam2 (match) {
      return match.perc != null ? 100 - match.perc : 0
    },
    toggleMore (match) {
      match.moreteam = !match.moreteam
    },
    pool (m, c, t) {
      var p = 0

      if (t != null) {
        p = (t.no * 100) + c.no
      } else {
        p = c.no
      }

      var k = this.selectPools[`m${m.no}c${p}`]
      return k != null && k > 0 ? 'selected' : ''
    },
    getpoint () {
      this.axios.post(`/wallets/${this.wallet}/getpoints`).then(
        ({ data }) => {
          this.$store.dispatch(UPDATE_WALLET_POINT, {
            no: data.no,
            points: data.points,
            time: data.nextgen
          })
        }
      ).finally(() => {
        this.dialogPoint = false
      })
    },
    pageadshow (row) {
      return this.pageads != null && (this.pageads[`p${row}0`] != null || this.pageads[`p${row}1`] != null || this.pageads[`p${row}2`] != null)
    },
    pageadstyle (row, pos) {
      if (this.pageads != null && this.pageads[`p${row}${pos}`] != null) {
        return {
          'background-image': `url(${this.pageads[`p${row}${pos}`].img})`
        }
      }

      return {}
    }
  },
  computed: {
    wallet() {
      return this.$route.params.wallet
    },
    guest () {
      return this.user.name == null
    },
    user () {
      return this.$store.getters['currentUser'] || {}
    },
    nomatches() {
      return this.matches.length === 0
    },
    timezone () {
      return dayjs().format('Z')
    },
    calcpoint () {
      return this.selected.rate * this.setpoint
    },
    wallets() {
      return this.$store.getters['currentWallet'].find(v => v.id === this.wallet)
    },
    walletpoint() {
      return this.wallets != null ? this.wallets.points : 0
    },
    nomatch() {
      return this.loading === false && this.matches.length === 0
    },
    headadsstyle () {
      if (this.headads) {
        return {
          'background-image': `url(${this.headads.img})`
        }
      }

      return {}
    }
  },
  watch: {
    wallet() {
      this.recvData()
      this.recvMatches()
    }
  },
  data() {
    return {
      info: {
        no: 0
      },
      games: [],
      matches: [],
      banners: [],
      selectedGame: '',
      points: {},
      headads: null,
      pageads: [],
      setrate: false,
      selected: {
        tourimg: '',
        tourname: '',
        matchname: '',
        match: 0,
        wallet: 0,
        choice: 0,
        title: '',
        team: '',
        teamlogo: '',
        rate: 0
      },
      setpoint: 0,
      loading: true,
      selectPools: {},
      dialogLogin: false,
      dialogPoint: false
    }
  }
};
</script>

<style lang="scss" scoped>
.game {
  cursor: pointer;
}

.gamename {
  width: 50px;
  font-size: 0.8rem;
}

@media (min-width: 768px) {
  .gamename {
    width: 75px;
    font-size: 0.925rem;
  }
}

.active {
  background-color: #39374a;
}

.pool {
  background-color: #1d212a;
  border-color: #292f3c;
}

.pool.selected {
  border-color: #005200;
  background-color: #194000;
}

.pool:hover {
  cursor: pointer;
  border-color: #a9a9a9;
  background-color: #242530;
}

.team1 {
  background-color: #c90707;
  color: #c90707;
}

.team2 {
  background-color: #07c927;
  color: #07c927;
}

.matchhead {
  background-color: #3b445a;
  margin-left: 0;
  margin-right: 0;
  padding-left: 5px;
  padding-right: 5px;
  border-top: 1px solid #14171D;

  .symbol-15 {
    width: 15px;
    height: 15px;
  }
}

.banner {
  width: 100%;
  position: relative;
  overflow: hidden;
  margin-top: 10px;

  .image300 {
    // height: 300px;
    padding-top: 23.26%;
    // background-color: #3b445a;
    background-size: cover;
    background-position: top;
    position: relative;

    a {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.banner2 {
  border-top: 1px solid #14171D;

  .image100 {
    height: 100px;
    // padding-top: 7.76%;
    // background-color: #3b445a;
    background-size: cover;
    background-position: center;
    position: relative;

    a {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

.extra {
  background-color: #4c4c4c;
}
</style>